import { getFirstMonthAmount, getPlan, getVivinoPlusLogInMessage } from '@helpers/vivinoPlus';
import {
  Currency,
  PlusPlan,
  PlusSubscription,
  SubscriptionStatus,
  getStoredPlusPlanIdByCart,
  removeStoredPlusPlanIdByCart,
  setStoredPlusPlanIdForCart,
  useI18N,
} from '@vivino/js-web-common';
import { PlusPlanWithCurrency } from '@webtypes/vivinoPlus';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CartContext } from 'vivino-js/context/CartContext';

import { getCurrencies } from '@lib/api/currencies';
import { getAvailablePlansForCart } from '@lib/api/vivinoPlus';

import { useUserContext } from '../UserContext';

interface VivinoPlusContextReturnType {
  isVivinoPlusSubscriber: boolean;
  isPlansReady: boolean;
  plusPlans: PlusPlan[];
  currencyMap: Record<string, Currency>;
  getPlans: () => void;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PlusPlan>>;
  setSubscription: React.Dispatch<React.SetStateAction<PlusSubscription>>;
  setIsNewSubscriber: React.Dispatch<React.SetStateAction<void>>;
  selectedPlan: PlusPlanWithCurrency;
  isPlanSelected: boolean;
  isLoginRequired: boolean;
  VIVINO_PLUS_LOGIN_MESSAGE: string | any[];
  isNewSubscriber: boolean;
  getStoredPlanIdForCart: () => number | null;
  removeStoredPlusPlanId: () => void;
  vivinoPlusAmount: number;
  togglePlusPlan: (v: boolean) => Promise<void>;
}

export const VivinoPlusContext = createContext<Partial<VivinoPlusContextReturnType>>({});

// Export hook to interact with context, to make it possible to mock results in testing.
export const useVivinoPlusContext = () => {
  return useContext(VivinoPlusContext);
};

export interface VivinoPlusContextProps {
  isVivinoPlusSubscriber?: boolean;
  plusPlan?: PlusPlanWithCurrency;
  children?: ReactNode;
}

export const VivinoPlusProvider = ({
  isVivinoPlusSubscriber,
  plusPlan,
  children,
}: VivinoPlusContextProps) => {
  const [selectedPlanState, setSelectedPlanState] = useState<PlusPlanWithCurrency | null>(plusPlan);
  const [plusPlans, setPlusPlans] = useState<PlusPlan[]>([]);
  const [currencyMap, setCurrencyMap] = useState<Record<string, Currency> | {}>({});
  const [subscription, setSubscription] = useState<PlusSubscription>();
  const [isNewSubscriber, setIsNewSubscriberState] = useState(false);
  const [vivinoPlusAmount, setVivinoPlusAmount] = useState(0);

  const { cart, updateCartContext, setPlusPlanId } = useContext(CartContext);
  const { signedIn } = useUserContext();
  const { t } = useI18N();

  const [storedPlusPlanId, setStoredPlusPlanId] = useState<number | null>(
    getStoredPlusPlanIdByCart(cart.id) || getStoredPlusPlanIdByCart(cart.transferred_from_cart_id)
  );

  const isPlanSelected = !!selectedPlanState?.plan_id && !!storedPlusPlanId;
  const availablePlusPlan = getPlan(plusPlans);

  const isSubscriptionActive =
    isVivinoPlusSubscriber || subscription?.status === SubscriptionStatus.Active;

  const isPlansReady = plusPlans && plusPlans.length > 0 && Object.keys(currencyMap).length > 0;

  const getPlans = async () => {
    const [availablePlans, currencies] = await Promise.all([
      getAvailablePlansForCart(cart),
      getCurrencies(),
    ]);

    setPlusPlans(availablePlans.plans);
    setCurrencyMap(currencies.currencies);
  };

  const togglePlusPlan = async (isSelected: boolean) => {
    if (!isSelected) {
      setSelectedPlan(null);
      return;
    }
    setPlusPlanId(availablePlusPlan?.plan_id);

    if (availablePlusPlan) {
      const planCurrencyCode = availablePlusPlan.currency_code;

      let currency = currencyMap?.[planCurrencyCode];
      if (!currency) {
        const { currencies } = await getCurrencies();
        currency = currencies[planCurrencyCode];
      }
      await setSelectedPlan({ ...availablePlusPlan, currency });
    }
  };

  const setSelectedPlan = async (selectedPlan: PlusPlanWithCurrency | null) => {
    const selectedPlanId = selectedPlan?.plan_id || null;

    await updateCartContext({ plus_plan_id: selectedPlanId });
    setSelectedPlanState(selectedPlan);
    setStoredPlusPlanId(selectedPlanId);

    // Store the selected plan id in the local storage to survive on page refresh.
    // Cart preview with plus is not persisted on the backend
    selectedPlanId ? setStoredPlusPlanIdForCart(cart.id, selectedPlanId) : removeStoredPlusPlanId();
  };

  const setIsNewSubscriber = () => {
    setIsNewSubscriberState(true);
  };

  const getStoredPlanIdForCart = () => storedPlusPlanId;

  const VIVINO_PLUS_LOGIN_MESSAGE = getVivinoPlusLogInMessage(t);

  const removeStoredPlusPlanId = () => {
    removeStoredPlusPlanIdByCart(cart.id);

    const transferredCartId = cart.transferred_from_cart_id;

    if (getStoredPlusPlanIdByCart(transferredCartId)) {
      removeStoredPlusPlanIdByCart(transferredCartId);
    }
  };

  useEffect(() => {
    if (isNewSubscriber || isSubscriptionActive) {
      setVivinoPlusAmount(0);
      return;
    }

    setVivinoPlusAmount(isPlanSelected ? getFirstMonthAmount(selectedPlanState) : 0);
  }, [isNewSubscriber, isSubscriptionActive, isPlanSelected]);

  useEffect(() => {
    if (isPlansReady) {
      // If a stored plus plan id is found, then check if we can automatically preselect it
      if (storedPlusPlanId) {
        const plan = getPlan(plusPlans);

        // make sure the available plan is the same as stored plan.
        // we have only one plan id available globally so it should always be true but adding this check
        // to future-proof it
        if (parseInt(String(storedPlusPlanId)) === plan.plan_id) {
          const planCurrencyCode = plan.currency_code;

          const currency = currencyMap[planCurrencyCode];

          setSelectedPlan({ ...plan, currency });
        }
      }
    }
  }, [isPlansReady, cart.id]);

  return (
    <VivinoPlusContext.Provider
      value={{
        getPlans,
        plusPlans,
        currencyMap,
        isPlansReady,
        setSelectedPlan,
        setSubscription,
        isNewSubscriber,
        isPlanSelected,
        setIsNewSubscriber,
        getStoredPlanIdForCart,
        removeStoredPlusPlanId,
        VIVINO_PLUS_LOGIN_MESSAGE,
        selectedPlan: selectedPlanState,
        vivinoPlusAmount: vivinoPlusAmount || 0,
        isVivinoPlusSubscriber: isSubscriptionActive,
        isLoginRequired: isPlansReady && isPlanSelected && !signedIn,
        togglePlusPlan,
      }}
    >
      {children}
    </VivinoPlusContext.Provider>
  );
};

export const VivinoPlusConsumer = VivinoPlusContext.Consumer;
